var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('Header'), _c('Content', {
    attrs: {
      "invoices-data": _vm.invoices,
      "header-data": _vm.tableHeaderData,
      "loaded": _vm.loaded
    },
    on: {
      "openInvoice": _vm.openInvoice,
      "openInvoiceBroker": _vm.openInvoiceBroker,
      "clearFilters": _vm.clearFilters,
      "getInvoices": _vm.getInvoices
    }
  }), _vm.selectedBroker ? _c('InvoiceBrokerSidepanel', {
    attrs: {
      "broker": _vm.selectedBroker
    },
    on: {
      "reloadInvoices": _vm.getInvoices,
      "closePanel": _vm.invoiceBrokerSidepanelClosed
    }
  }) : _vm._e(), _vm.selectedInvoice ? _c('InvoiceSidepanel', {
    attrs: {
      "invoice": _vm.selectedInvoice
    },
    on: {
      "reloadInvoice": _vm.reloadInvoice,
      "addInvoice": _vm.invoiceAdded,
      "deleteInvoice": _vm.invoiceDeleted,
      "closePanel": _vm.invoiceSidepanelClosed
    }
  }) : _vm._e(), _vm.selectedPaymentWidgetInvoice ? _c('InvoicePaymentWidget', {
    attrs: {
      "invoice": _vm.selectedPaymentWidgetInvoice,
      "pre-fill-unpaid": true
    },
    on: {
      "closeWidget": function ($event) {
        _vm.selectedPaymentWidgetInvoice = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }