<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <Header/>
    <Content
        @openInvoice="openInvoice"
        @openInvoiceBroker="openInvoiceBroker"
        @clearFilters="clearFilters"
        @getInvoices="getInvoices"
        :invoices-data="invoices"
        :header-data="tableHeaderData"
        :loaded="loaded"
    />
    <InvoiceBrokerSidepanel
        v-if="selectedBroker"
        :broker="selectedBroker"
        @reloadInvoices="getInvoices"
        @closePanel="invoiceBrokerSidepanelClosed"
    />
    <InvoiceSidepanel
        v-if="selectedInvoice"
        :invoice="selectedInvoice"
        @reloadInvoice="reloadInvoice"
        @addInvoice="invoiceAdded"
        @deleteInvoice="invoiceDeleted"
        @closePanel="invoiceSidepanelClosed"
    />
    <InvoicePaymentWidget
        v-if="selectedPaymentWidgetInvoice"
        :invoice="selectedPaymentWidgetInvoice"
        :pre-fill-unpaid="true"
        @closeWidget="selectedPaymentWidgetInvoice = null"
    />
  </div>
</template>

<script>
import Header from "../../components/Invoices/Header.vue";
import Content from "../../components/Invoices/Content.vue";
import {mapActions, mapGetters} from "vuex";
import InvoiceSidepanel from "@/components/Invoices/InvoiceSidepanel";
import axios from "axios";
import InvoicePaymentWidget from "@/components/Invoices/InvoicePaymentWidget";
import InvoiceBrokerSidepanel from "@/components/Invoices/InvoiceBrokerSidepanel";

export default {
  name: "Invoices",
  components: {
    InvoiceBrokerSidepanel,
    InvoicePaymentWidget,
    InvoiceSidepanel,
    Header,
    Content
  },
  data() {
    return {
      filterConditions: null,
      invoices: [],
      tableHeaderData: {},
      selectedInvoice: null,
      selectedPaymentWidgetInvoice: null,
      selectedBroker: null,
      loaded: false,
      tableUpdater: 0,
    }
  },
  computed: {
    ...mapGetters([
      "provisionalFilters",
      "provisionalFiltersUpdater",
      "filters",
      "invoiceSummariesWithVat",
      "activeInvoicesTab",
      "users",
      "user",
      "invoiceUpdater"
    ]),
    colleagues() {
      if (this.user.isAdmin || this.user.companyPolicies.filters.transactions.showAll) {
        return [
          {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
        ].concat(this.users)
      } else {
        let users = this.users.filter((user) => user.adminAccess);
        if (users.length > 0) {
          return [
            {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
          ].concat(users)
        } else {
          return []
        }
      }
    }
  },
  async created() {
    await this.initializeFilters();
  },
  watch: {
    async provisionalFiltersUpdater() {
      await this.getInvoices();
    },
    async activeInvoicesTab() {
      await this.getInvoices()
    },
    async invoiceUpdater() {
      await this.getInvoices()
    }
  },
  methods: {
    ...mapActions([
      "setProvisionalFilter"
    ]),
    async setupInvoiceFieldFilter(invoices, headerData) {
      let brokerFilter = this.provisionalFilters["invoices"].find((filter) => filter.name === "brokerId")
      this.tableHeaderData = headerData;
      if (brokerFilter.value) {
        if (brokerFilter.value.type === "user") {
          return await this.setupInvoiceFieldForUsers({[brokerFilter.value.value]: true}, invoices)
        } else {
          const groupUsers = await this.getGroupUsers(brokerFilter.value.value)
          return await this.setupInvoiceFieldForUsers(groupUsers, invoices)
        }
      }
      return invoices;
    },
    async setupInvoiceFieldForUsers(users, invoices) {
      const returnedInvoices = []
      for (const invoice of invoices) {
        if (invoice.sharedBrokers && invoice.sharedBrokers.length > 0) {
          // Total invoice values.
          invoice.totalSum = invoice.sum;
          invoice.totalSumVAT = invoice.sumVAT;
          // Replaced with broker values to keep sorting working.
          invoice.sum = 0;
          invoice.sumVAT = 0;
          for (const sharedBroker of invoice.sharedBrokers) {
            if (users[sharedBroker._id]) {
              invoice.sum += parseFloat(sharedBroker.share.sum);
              invoice.sumVAT += parseFloat(sharedBroker.share.sumVAT);
            }
          }
          if (users[invoice.broker.userId]) {
            invoice.sum += parseFloat(invoice.broker.share.sum);
            invoice.sumVAT += parseFloat(invoice.broker.share.sumVAT);
          }
          invoice.sum = this.formatFixed(invoice.sum, 0);
          invoice.sumVAT = this.formatFixed(invoice.sumVAT, 0);
          if (invoice.totalSum === invoice.sum && invoice.totalSumVAT === invoice.sumVAT) {
            delete invoice.totalSum;
            delete invoice.totalSumVAT;
          }
        }
        returnedInvoices.push(invoice);
      }
      return returnedInvoices;
    },
    formatFixed(value, toFixedDecimalCount) {
      let num = value;

      // Handle undefined or null values
      if (num === undefined || num === null) return num;

      // Convert to number
      num = Number(num);

      // Check if it's a whole number
      if (num % 1 !== 0) {
        // If not, return it with 2 decimal places
        return parseFloat(num.toFixed(toFixedDecimalCount));
      } else {
        // If it is, return it without decimal places
        return parseFloat(num.toFixed(0));
      }
    },
    async getInvoices() {
      const requestURI = this.activeInvoicesTab === 'invoices' ? "/api/invoices" : "/api/invoices/brokers"
      this.loaded = false;
      const invoiceResponse = await axios.post(requestURI, {
        filters: this.provisionalFilters["invoices"],
        summariesWithVat: this.invoiceSummariesWithVat
      });
      if (this.activeInvoicesTab === 'invoices') {
        this.invoices = await this.setupInvoiceFieldFilter(invoiceResponse.data.invoices, invoiceResponse.data.headerData)
      } else {
        this.invoices = invoiceResponse.data.invoices;
        this.tableHeaderData = invoiceResponse.data.headerData;
      }
      this.loaded = true;
    },
    async initializeFilters() {
    
      if (!this.filters["invoices"][0].conditions.find((condition) => condition.name === 'invoiceDate')) {
        this.filters["invoices"][0].conditions.push({
          name: "invoiceDate",
          title: "InvoiceDate",
          value: 'this_month',
          valueTitle: "",
        })
      }
      this.filters["invoices"].find(
          (item) => item._id === '4'
      ).conditions.find((condition) => condition.name === 'invoiceDate').value = 'this_month';
      if (this.filters["invoices"][0].conditions.find((condition) => condition.name === 'invoiceDateType')) {
        this.filters["invoices"][0].conditions.find((condition) => condition.name === 'invoiceDateType').value = null
      }
      const savedViewId = this.$route.params.savedViewId;
      if (savedViewId) {
        this.filterConditions = this.filters["invoices"].find((filter) => filter._id === savedViewId).conditions;
      } else {
        // default filter
        const filter = this.filters["invoices"].find((filter) => filter.isDefault);
        this.filterConditions = filter.conditions;
        const brokerField = this.filterConditions.find((condition) => condition.name === 'brokerId')
        if (this.user.access.accessLevel === "company_admin") {
          if (filter.name === "allValues") {
            brokerField.value = null;
            filter.filterTitle = "AllInvoices";
          }
        } else {
          if (filter.name === "allValues") {
            brokerField.value = {
              type: "user",
              value: this.user._id
            }
            filter.filterTitle = "MyInvoices";
          }
        }
      }

      if (!this.filterConditions) {
        this.filterConditions = this.filters["invoices"][0].conditions;
      }
      await this.setProvisionalFilter({
        filterName: "invoices",
        conditions: this.filterConditions,
      });
    },
    async clearFilters() {
      let conditions = this.filters['invoices'].find(
          (item) => item.isDefault
      ).conditions
      if (!conditions) {
        conditions = this.filters['invoices'][0].conditions;
      }
      await this.setProvisionalFilter({
        filterName: 'invoices',
        conditions: conditions,
      });
    },
    openInvoice(data) {
      this.selectedInvoice = data;
    },
    openInvoiceBroker(data) {
      console.log(data)
      this.selectedBroker = data;
    },
    invoiceSidepanelClosed() {
      this.selectedInvoice = null;
    },
    invoiceBrokerSidepanelClosed() {
      this.selectedBroker = null;
    },
    async invoiceAdded() {
      this.invoiceSidepanelClosed();
      await this.getInvoices()
    },
    async reloadInvoice() {
      await this.getInvoices();
      if (this.selectedInvoice) {
        this.selectedInvoice = this.invoices.find((invoice) => invoice._id === this.selectedInvoice._id)
      }
    },
    async invoiceDeleted() {
      await this.getInvoices();
      this.selectedInvoice = null;
    },
  }
}
</script>

<style scoped>

</style>